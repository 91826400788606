import React, { useContext, useRef, useEffect, useState, useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import queryString from 'query-string'
import Layout from '../components/layout'
import Seo from "../components/seo"
import Container from "react-bootstrap/Container"
import Accordion from "react-bootstrap/Accordion"
import { AccordionContext } from "react-bootstrap"
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import MainCTA from "../components/sections/main-cta"

import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(ScrollToPlugin, SplitText);

function CustomCard({ eventKey, content, title, setActiveKey }) {
  const { activeEventKey } = useContext(AccordionContext);
  const cardRef = useRef(null);
  const toggleItem = useAccordionButton(
    eventKey,
    () => {
      if (isActive) {
        setActiveKey(null); // Close the card if it's already active
      } else {
        setActiveKey(eventKey); // Open the card
      }
    },
  );

  const isActive = activeEventKey === eventKey;
  
  const handleEntered = () => {
    gsap.to(window, { duration: 0.2, scrollTo: { y: cardRef.current.offsetTop - 100 } });
  };
  

  useEffect(() => {
    if (isActive) {
      handleEntered();

      // Update the URL's query parameter
      const newUrl = new URL(window.location);
      newUrl.searchParams.set('card', formatTitleForURL(title)); // Use the formatted title here
      window.history.pushState({}, '', newUrl);
    }
  }, [isActive]);

  return (
    <Card ref={cardRef} className={`rounded-4 ${isActive ? 'active' : ''}`}>
      <Card.Title
        role="button"
        onClick={toggleItem}
        className="p-1 p-lg-2 p-xxl-2 px-xxxl-3 mb-0 d-flex flex-column flex-sm-row justify-content-between"
        as="div"
      >
        <h2 className="h4 fw-bolder me-sm-1 mb-0 order-1 order-sm-0">
          {title}
        </h2>
      </Card.Title>
      <Accordion.Collapse eventKey={eventKey} onEntered={handleEntered}>
        <Card.Body className="pt-0 px-lg-2 pb-lg-2 px-xxxl-3">
          <Card.Text as="div" className="p-1 p-lg-2 p-xxl-3 px-xxxl-4 bg-light rounded-4" dangerouslySetInnerHTML={{ __html: content }} />
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}

function formatTitleForURL(title) {
  // Remove content after (and including) a parenthesis and trim any leading spaces
  const cleanedTitle = title.split('(')[0].trim();

  // Take at most the first 4 words
  const limitedWordsTitle = cleanedTitle.split(' ').slice(0, 4).join(' ');

  // Remove special characters except numbers and letters, convert spaces to dashes, and convert to lowercase
  const formattedTitle = limitedWordsTitle.replace(/[^a-zA-Z0-9\s]/g, '').replace(/\s+/g, '-').toLowerCase();

  return formattedTitle;
}


const MushroomRootPage = ({ location }) => {

  const [activeKey, setActiveKey] = useState(null);
  
  const myceliumInfo = useStaticQuery(graphql`
    query {
      allWpPage(filter: {title: {eq: "Mycelium"}}) {
        nodes {
          title
          content
          myceliumInfo {
            myceliumInfo {
              title
              content
            }
          }
        }
      }
    }
  `)

  const { card: activeCardQuery } = queryString.parse(location.search)

  // Create a new array of cards with the formatted title added as a property
  const formattedCards = useMemo(() =>
    myceliumInfo.allWpPage.nodes[0].myceliumInfo.myceliumInfo.map(node => ({
      ...node,
      formattedTitle: formatTitleForURL(node.title)
    })),
  [myceliumInfo]);

  const activeCardIndex = formattedCards.findIndex(cardNode => activeCardQuery === cardNode.formattedTitle);

  useEffect(() => {
    if (activeCardIndex !== -1) {
      setActiveKey(activeCardIndex.toString());
      //gsap.to(window, { duration: 0.2, scrollTo: { y: cardRef.current.offsetTop - 100 } });
    }
    else {
      gsap.to(window, { duration: 0.35, scrollTo: 0 });
    }
  }, [activeCardIndex]);

  return (
    <Layout className={"mushroom-root"}>
      <Seo title="Mushroom Root 101" />
      <header className="py-2 pt-xl-3 bg-light text-center text-danger m-0">
        <Container>
          <h1 className="fs-3 ff-sans-serif">MushroomRoot™ 101</h1>
        </Container>
      </header>
      <section className="pb-2 pb-md-5 mycelium-cards bg-light">
        <Container>
          <Accordion id="mushroomRootAccordion" activeKey={activeKey}>
            {
              formattedCards.map((cardNode, index) =>
                <CustomCard
                  eventKey={index.toString()}
                  content={cardNode.content}
                  title={cardNode.title}
                  setActiveKey={setActiveKey}
                />
              )
            }
          </Accordion>
        </Container>
      </section>
      <MainCTA />
    </Layout>
  )
}

export default MushroomRootPage